<template>
    <div class="allindex">
        <!-- 引入模块 -->
        <Left ref="Left"></Left>
        <Top ref="Top"></Top>
        <div class="all-main" :class="mainActive ? 'active' :''">
            <div class="main-index">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
// 引入模块
import Left from "./left";
import Top from "./top";
export default {
    data() {
        return {
            mainActive: false,
        };
    },
    // 声明模块
    components: {
        Left,
        Top,
    },
    mounted() {},

    methods: {
        // 收起展开侧边栏
        onBarPackup() {
            this.$refs.Left.onBarPackup();
            this.mainActive = !this.mainActive; //改变main宽度
        },
        //一级分类切换类
        onLeftpath(menu) {
            this.$refs.Left.onLeftpath(menu);
        },
    },
};
</script>

<style lang="scss" scoped>
.allindex {
    background: #f2f7fa;
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    .all-main {
        // position: absolute;
        // min-height: calc(100vh - 56px - 20px);
        // top: calc(56px + 20px);
        // left: calc(200px + 20px);
        // right: 20px;
        padding-left: calc(200px + 20px);
        padding-top: calc(56px + 20px);
        padding-right: 20px;
        padding-bottom: 20px;
        text-align: left;
        background: #f2f7fa;
        transition: 0.5s;
        &.active {
            transition: 0.5s;
            padding-left: calc(64px + 20px);
        }
        .main-index {
            background: #fff;
            min-height: calc(100vh - 96px);
        }
        // padding: 20px;
    }
}
</style>

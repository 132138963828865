<template>
    <!-- <div class="websocket" v-if="webcation">
        <div class="title">
            通知
            <span class="icon" @click="onWebcation"><i class="el-icon-close"></i></span>
        </div>
        <div class="texthtml">
            <div v-if="socketData.type == 1" class="massagehtml">{{socketData.message}}</div>
            <div v-if="socketData.type == 2" class="massagehtml">
                <p>{{socketData.media.message}}</p>
                <el-button type="primary" @click="onToPath">去处理</el-button>
            </div>
        </div>
        <audio class="myaudio" id="Myaudio" :src="socketData.media.media_url" controls="controls"></audio>
    </div> -->
    <div class="websocket" v-if="webcation">
        <div class="title">
            通知
            <span class="icon" @click="onWebcation"><i class="el-icon-close"></i></span>
        </div>
        <div class="texthtml">
            <div class="massagehtml" v-for="(item,key) in socketData" :key="key" v-if="key+1 == socketKey">
                <p>{{item.type == 1 ? item.message : item.media.message}}</p>
                <el-button type="primary" @click="onToPath(item)" v-if="item.type == 2">去处理</el-button>
            </div>
        </div>
        <div class="paging">
            <div class="before button" @click="onBefore"><i class="el-icon-arrow-left"></i></div>
            <div class="text">{{socketKey}}/{{socketData.length}}</div>
            <div class="after button" @click="onAfter"><i class="el-icon-arrow-right"></i></div>
        </div>
        <audio class="myaudio" id="Myaudio" :src="socketData[0].media.media_url" controls="controls"></audio>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // websocke
            path: process.env.VUE_APP_URL_WSS,
            socket: "",
            webcation: false, //判断出现消失
            // socketData: {},
            dingshiTIme: 1,
            socketData:[],
            socketKey:1,
        };
    },
    mounted() {
        // 初始化
        this.init();
        
    },
    methods: {
        //上一个
        onBefore(){
            this.socketKey > 1 ? this.socketKey-- : this.$message({message: '已经是第一条了',type: 'warning'});
        },
        //下一个
        onAfter(){
            this.socketKey < this.socketData.length ? this.socketKey++ : this.$message({message: '已经到最后一条了',type: 'warning'});
        },
        init() {
            if (typeof WebSocket === "undefined") {
                alert("您的浏览器不支持socket");
            } else {
                // 实例化socket
                this.socket = new WebSocket(this.path);
                // 监听socket连接
                this.socket.onopen = this.open;

                this.socket.onclose = this.close;
                // 监听socket错误信息
                this.socket.onerror = this.error;
                // 监听socket消息
                this.socket.onmessage = this.getMessage;
            }
        },
        // socket连接成功
        open() {
            // console.log("socket连接成功")
            setInterval(() => {
                this.socket.send("1");
            }, 10000);
        },
        // 连接错误
        error() {
            // 链接错误重新建立链接
            // this.init()
            // this.onconfirm()
            // console.log("连接错误")
        },
        // 数据接收做动作
        getMessage(res) {
            if (res.data == "success") {
                return;
            }
            // console.log(res,123123)
            var res = JSON.parse(res.data);
            if (res.code == 200) {
                // var socketData = this.socketData
                // res.data.forEach((v,k) => {
                //     socketData.push(v)
                // });
                this.socketData.push(res.data)
                // console.log(this.socketData)
                this.webcation = true;
                if (res.data.media.media_is_play) {
                    if (document.getElementById("Myaudio")) {
                        document.getElementById("Myaudio").play();
                    }
                }
                
                // console.log(this.socketData);
            }
        },
        send(res) {
            // this.socket.send(JSON.parse(res.data));
        },
        // socket已经关闭
        close() {
            this.onconfirm();
            // console.log("socket已经关闭");
        },
        onWebcation() {
            this.webcation = false;
            this.socketData = []
            // console.log(123123);
        },
        //弹窗
        onconfirm() {
            this.$confirm(
                "为节省网络与服务器资源，通知服务已从服务器断开，点击连接按钮重新连接",
                "提示",
                {
                    confirmButtonText: "连接",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.$router.go(0);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消连接，如需重新连接刷新页面即可",
                    });
                });
        },
        // 跳转页面
        onToPath(item) {
            if(this.$route.path == "/" + item.jump_url){
                this.$router.go(0)
            }else{
                this.$router.push("/" + item.jump_url);
            }
            this.socketData = []//处理后删除数据
        },
        // 清空数据
        onEmtpy() {
            (this.webcation = false), (this.socketData = []);
        },
    },
    destroyed() {
        this.socket.onclose = this.close;
    },
};
</script>

<style lang="scss" scoped>
.websocket {
    position: fixed;
    width: 400px;
    padding: 20px;
    top: 60px;
    right: 20px;
    background: #fff;
    border-radius: 10px;
    z-index: 100;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    transition: opacity 0.3s, transform 0.3s, left 0.3s, right 0.3s, top 0.4s,
        bottom 0.3s;
    animation: myfirst 2s;
    @-webkit-keyframes myfirst {
        0% {
            right: -400px;
        }
    }
    border-left: 4px solid #0075f7;
    .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        .icon {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 22px;
        }
    }
    .myaudio {
        display: none;
    }
    .texthtml {
        .massagehtml {
            position: relative;
            padding-bottom: 50px;
            .el-button {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }
    .paging{
        display: flex;
        position: absolute;
        bottom: 20px;
        .button{
            cursor: pointer;
            padding: 0 5px;
        }
    }
}
</style>
<style lang="scss">
</style>
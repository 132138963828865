import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from 'element-ui';
import Allpubilc from "@/views/index/all";
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
Vue.use(VueRouter);

const routerApp = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/",
            component: Allpubilc,
            redirect: "/",
            children: [
                {
                    path: '/',
                    component: () => import('@/views/index/adminindex'),
                    meta: { title: "首页", selected: '首页' },
                }, {
                    path: '/ErlangWrb',
                    component: () => import('@/views/index/index'),
                    meta: { title: "二郎查", selected: '二郎查', },
                }, {
                    path: '/webdownload',
                    component: () => import('@/views/webdownload'),
                    meta: { title: "下载", selected: '首页', },
                }, {
                    path: '/about',
                    component: () => import('@/views/index/about'),
                    meta: { title: "下载", selected: '首页', },
                },
                //二郎查工具
                {
                    path: '/flock',
                    component: () => import('@/views/index/flock'),
                    meta: { title: "企业列表", selected: '二郎查工具', },
                }, {
                    path: '/operation',
                    component: () => import('@/views/index/operation'),
                    meta: { title: "运营官列表", selected: '二郎查工具', },
                }, {
                    path: '/orderFirm',
                    component: () => import('@/views/index/upOrder/orderFirm'),
                    meta: { title: "充值订单列表", selected: '二郎查工具', },
                }, {
                    path: '/grouplist',
                    component: () => import('@/views/grouplist'),
                    meta: { title: "群列表", selected: '二郎查工具', },
                }, {
                    path: '/AccountList',
                    component: () => import('@/views/index/Account/AccountList'),
                    meta: { title: "账户列表", selected: '二郎查工具', },
                }, {
                    path: '/prot',
                    component: () => import('@/views/index/Prot/prot'),
                    meta: { title: "端口管理", selected: '二郎查工具', },
                }, {
                    path: '/ticketCreate',
                    component: () => import('@/views/index/ticketCreate'),
                    meta: { title: "掉量超成本", selected: '二郎查工具', },
                }, {
                    path: '/ticketCreate/ticketCreateInfo',
                    component: () => import('@/views/index/ticketCreate/ticketCreateInfo'),
                    meta: { title: "掉量超成本详情", selected: '二郎查工具', },
                }, {
                    path: '/crossPortList',
                    component: () => import('@/views/index/refund/crossPortList'),
                    meta: { title: "跨端口退款", selected: '二郎查工具', },
                }, {
                    path: '/topuprefund',
                    component: () => import('@/views/index/refund/topuprefund'),
                    meta: { title: "充值退款", selected: '二郎查工具', },
                }, {
                    path: '/lobarreview',
                    component: () => import('@/views/index/lobarreview'),
                    meta: { title: "充值退款", selected: '二郎查工具', },
                }, {
                    path: '/consumption',
                    component: () => import('@/views/index/consumption'),
                    meta: { title: "消耗统计", selected: '二郎查工具', },
                },{
                    path: '/yourkind',
                    component: () => import('@/views/index/refund/yourkind'),
                    meta: { title: "充值备款", selected: '二郎查工具', },
                },{
                    path: '/overView',
                    component: () => import('@/views/index/overView'),
                    meta: { title: "企业总览", selected: '二郎查工具', },
                },

                //财务管理
                {
                    path: '/financial/orderList',
                    component: () => import('@/views/index/financial/orderList'),
                    meta: { title: "订单统计", selected: '二郎查工具', },
                }, {
                    path: '/financial/portStatistics',
                    component: () => import('@/views/index/financial/portStatistics'),
                    meta: { title: "端口统计", selected: '二郎查工具', },
                },


                //用户管理
                {
                    path: '/userlist',
                    component: () => import('@/views/user/userlist'),
                    meta: { title: "用户列表", selected: '用户管理', },
                }, {
                    path: '/discern',
                    component: () => import('@/views/discern'),
                    meta: { title: "文字识别", selected: '用户管理', },
                },{
                    path: '/interview',
                    component: () => import('@/views/user/interview'),
                    meta: { title: "最新访问", selected: '用户管理', },
                },
                 // 对账列表
                {
                    path: '/reconstatistics',
                    component: () => import('@/views/reconciliation/statistics/index'),
                    meta: { title: "对账列表", selected: '二郎查工具', },
                }, {
                    path: '/reconconsume',
                    component: () => import('@/views/reconciliation/consume/index'),
                    meta: { title: "账户消耗", selected: '二郎查工具', },
                },
                // 筛选账户
                {
                    path: '/screenAccount',
                    component: () => import('@/views/reconciliation/screenAccount'),
                    meta: { title: "筛选账户", selected: '二郎查工具', },
                },
                // 筛选产品
                {
                    path: '/screenProduct',
                    component: () => import('@/views/reconciliation/screenProduct'),
                    meta: { title: "筛选产品", selected: '二郎查工具', },
                },
                {
                    path: '/screenPersonnel',
                    component: () => import('@/views/reconciliation/personnel'),
                    meta: { title: "筛选人员", selected: '二郎查工具', },
                },
                //系统列表
                {
                    path: '/message',
                    component: () => import('@/views/system/message/index'),
                    meta: { title: "消息模板", selected: '系统管理', },
                }, {
                    path: '/inform',
                    component: () => import('@/views/system/inform/index'),
                    meta: { title: "通知列列表", selected: '系统管理', },
                }, {
                    path: '/accesslog',
                    component: () => import('@/views/system/accesslog/index'),
                    meta: { title: "访问日志", selected: '系统管理', },
                }, 
                {
                    path: '/source',
                    component: () => import('@/views/system/source/index'),
                    meta: { title: "访问来源统计", selected: '系统管理', },
                },     
                {
                    path: '/statistics',
                    component: () => import('@/views/system/statistics/index'),
                    meta: { title: "鲁班商品统计", selected: '系统管理', },
                }, {
                    path: '/BatchPlan',
                    component: () => import('@/views/system/BatchPlan/index'),
                    meta: { title: "批量计划访问统计", selected: '批量计划工具', },
                },{
                    path: '/userPlan',
                    component: () => import('@/views/system/userPlan/index'),
                    meta: { title: "批量计划用户管理", selected: '批量计划工具', },
                },{
                    path: '/statisticsPlan',
                    component: () => import('@/views/system/statisticsPlan/index'),
                    meta: { title: "计划统计", selected: '批量计划工具', },
                },

                //后台权限管理
                {
                    path: '/AdminList',
                    component: () => import('@/views/system/Admin/AdminList'),
                    meta: { title: "权限管理", selected: '系统管理', },
                },{
                    path: '/AdminUser',
                    component: () => import('@/views/system/Admin/AdminUser'),
                    meta: { title: "用户权限", selected: '系统管理', },
                },
                {
                    path: '/menuIndex',
                    component: () => import('@/views/system/Admin/AdminList/menuindex'),
                    meta: { title: "菜单栏", selected: '系统管理', },
                },





                //二郎查
                {
                    path: '/ErLangCha/LubanShop',
                    component: () => import('@/views/ErLangcha/lubanshop'),
                    meta: { title: "鲁班商品", selected: '二郎查', },
                }, {
                    path: '/ErLangCha/LubanStore',
                    component: () => import('@/views/ErLangcha/lubanstore'),
                    meta: { title: "鲁班店铺", selected: '二郎查', },
                }, {
                    path: '/ErLangCha/teacher',
                    component: () => import('@/views/ErLangcha/teacher'),
                    meta: { title: "配音师列表", selected: '二郎查', },
                }, {
                    path: '/ErLangCha/dubbingorders',
                    component: () => import('@/views/ErLangcha/dubbingorders'),
                    meta: { title: "配音师订单列表", selected: '二郎查', },
                }, {
                    path: '/ErLangCha/VideoOrder',
                    component: () => import('@/views/ErLangcha/VideoOrder'),
                    meta: { title: "视频订单列表", selected: '二郎查', },
                }, {
                    path: '/ErLangCha/teacher/case',
                    component: () => import('@/views/ErLangcha/teacher/case'),
                    meta: { title: "案例", selected: '二郎查', },
                }, {
                    path: '/ErLangCha/dubOrder',
                    component: () => import('@/views/ErLangcha/dubOrder'),
                    meta: { title: "配音订单", selected: '二郎查', },
                }, {
                    path: '/ErLangCha/integral',
                    component: () => import('@/views/ErLangcha/integral'),
                    meta: { title: "积分下单设置", selected: '二郎查', },
                },
                {
                    path: '/ErLangCha/holiday',
                    component: () => import('@/views/ErLangcha/holiday'),
                    meta: { title: "节假日设置", selected: '二郎查', },
                },
                {
                    path: '/ErLangCha/contrast',
                    component: () => import('@/views/ErLangcha/contrast'),
                    meta: { title: "自运营对比店铺", selected: '二郎查', },
                },
                {
                    path: '/ErLangCha/urlLink',
                    component: () => import('@/views/ErLangcha/urlLink'),
                    meta: { title: "友情链接", selected: '二郎查', },
                }
                ,{
                    path: '/ErLangCha/article',
                    component: () => import('@/views/ErLangcha/article'),
                    meta: { title: "文章列表", selected: '二郎查', },
                }
                ,{
                    path: '/ErLangCha/author',
                    component: () => import('@/views/ErLangcha/author'),
                    meta: { title: "作者列表", selected: '二郎查', },
                },{
                    path: '/ErLangCha/questions',
                    component: () => import('@/views/ErLangcha/questions'),
                    meta: { title: "有问必答", selected: '二郎查', },
                },{
                    path: '/ErLangCha/plugCode',
                    component: () => import('@/views/ErLangcha/plugCode'),
                    meta: { title: "插件激活码", selected: '二郎查', },
                },{
                    path: '/ErLangCha/orderplug',
                    component: () => import('@/views/ErLangcha/orderplug'),
                    meta: { title: "下单插件", selected: '二郎查', },
                },
                
            ],
        },
        {
            path: '/login',
            component: () => import('@/views/login'),
            meta: { title: "登录",nologin:true, },
        },
        {
            path: '/analysis',
            component: () => import('@/views/system/analysis/index'),
            meta: { title: "鲁班商品分析", selected: '系统管理', nologin:true,},
        },
        {
            path: '/shangxin',
            component: () => import('@/views/system/shangxin/index'),
            meta: { title: "跑品销量", selected: '系统管理', Bodytitle: '在跑品类 销量第一店铺48小时内上新', nologin:true,},
        },
        {
            path: '/qunian',
            component: () => import('@/views/system/qunian/index'),
            meta: { title: "去年今天销量榜", selected: '系统管理', Bodytitle: '去年今天销量榜', nologin:true,},
        },
        {
            path: '/searchWord',
            component: () => import('@/views/system/searchWord'),
            meta: { title: "搜索词", selected: '二郎查', },
        }

    ],
    //Vue路由页面之间滚动位置互相影响
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})
routerApp.beforeEach((to, from, next) => {
    if (to.meta.Bodytitle) {
        document.title = to.meta.Bodytitle;
    }
    NProgress.start();
    if (to.matched.length === 0) { //匹配前往的路由不存在
        from.name ? next({
            name: from.name
        }) : next('/'); //判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
    } else {
        //如果匹配到正确跳转
        // 配置路由权限判断是否登录
        if (localStorage.getItem('TZ-USER')) {
            next()
        } else {
            if(!to.meta.nologin){
                Message({
                    showClose: true,
                    message: '请先登录!',
                    type: "warning"
                });
                next('/login')
            }else{
                next()
            }
            console.log(to.meta.nologin,123)
            // if (to.fullPath != '/login' && to.fullPath != '/shangxin') {
            //     Message({
            //         showClose: true,
            //         message: '请先登录!',
            //         type: "warning"
            //     });
            //     // next('/login')
            // }
            // next()
        }
        // next()
    }
    // 
});
routerApp.afterEach(() => {
    NProgress.done();
});
const routes = [
    // {
    // 	path: "/",
    // 	component: Allpubilc,
    // 	redirect: "/",
    // 	children: [
    // 		{
    // 			path: '/',
    // 			component: () => import('@/views/index/index'),
    // 			meta: { title: "首页", },
    // 		},
    // 	]
    // }
    //   {
    //     path: "/about",
    //     name: "About",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //       import(/* webpackChunkName: "about" */ "../views/About.vue"),
    //   },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default routerApp;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import axios from 'axios'
import md5 from 'js-md5';
import service from './common/service';
import api from './common/api'
import smallToBig from './common/smallToBig'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/theme/index.css';
import './assets/css/base.css';
import './assets/css/allindex.scss';
import "@/assets/font/iconfont.css";
import VueClipboard from 'vue-clipboard2'


import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

let Base64 = require('js-base64').Base64;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$axios = axios
Vue.prototype.$md5 = md5
Vue.prototype.Base64 = Base64

Vue.use(VueClipboard)

//接口封装
Vue.prototype.$service = service
//全局token
if(localStorage.getItem('TZ-USER')){
  Vue.prototype.Token = JSON.parse(localStorage.getItem('TZ-USER')).admin_token;
}
Vue.prototype.$api = api
//数字转文字
Vue.prototype.smallToBig = smallToBig
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

//时间转换
Vue.prototype.dateFtt = function (fmt, date) { //author: meizz 
  var o = {
    "M+": date.getMonth() + 1,     //月份 
    "d+": date.getDate(),     //日 
    "h+": date.getHours(),     //小时 
    "m+": date.getMinutes(),     //分 
    "s+": date.getSeconds(),     //秒 
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
    "S": date.getMilliseconds()    //毫秒 
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
// 千分位
Vue.prototype.ThousandSeparator = function (input) {
  return input && input.toString()
  .replace(/(^|\s)\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}


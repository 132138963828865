<template>
    <div class="roof">
        <div class="logo">
            <img src="https://2lc-oss.erlangcha.com/dapingimg2/daptu2.png" alt="">
            <div class="shrinkage" @click="onBarPackup()"><i class="iconfont icon-cebiandaohangshouqi"></i></div>
        </div>
        <div class="topExio">
            <li v-for="(item,key) in topExioList" :key="key" :class="selected == item.text ? 'active' : ''" @click="onToPath(item)">
                <i class="iconfont" :class="item.icon"></i>
                <span>{{item.text}}</span>
                <p class="xian"></p>
            </li>
        </div>
        <!-- <div class="websocket" v-if="!wechat_data.wechat_name">
            <img src="../../../public/wx.jpg" alt="" style="width:160px;height:160px;margin-top:0px">
            <div class="title">
                第一步: 扫上方二维码关注 “二郎查工具” 公众号。
            </div>
            <div class="title">
                第二步: (点击红字)<span @click="onWx" style="color:#f00; font-size:36px;cursor: pointer;">绑定微信</span>以收取服务通知。
            </div>
        </div> -->
        <div class="topExio right">
            <li @click="towebdownload('/webdownload')">
                <i class="el-icon-download"></i>
                <span>下载中心</span>
                <p class="xian"></p>
            </li>
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
                <el-submenu index="2">
                    <template slot="title"> <i class="iconfont icon-yonghu"></i> {{account}}</template>
                    <el-menu-item v-if="wechat_data.wechat_name" index="2-1">
                        <img :src="wechat_data.wechat_head" alt="" style="   width: 30px;height: 30px;border-radius:15px;margin-left:30px;">
                        <span style="margin-left:10px">
                            {{Base64.decode(wechat_data.wechat_name)}}
                        </span>
                    </el-menu-item>
                    <el-menu-item @click="onWx" index="2-2">
                        <div style="width:100%;">
                            <span style="margin-left:30px;">
                                {{wechat_data.wechat_name?"重新绑定":"绑定微信"}}
                            </span>
                        </div>
                    </el-menu-item>
                </el-submenu>
            </el-menu>
            <!-- <li>
                <i class="iconfont icon-yonghu"></i>
                <span>{{account}}</span>
                <p class="xian"></p>
            </li> -->
            <li @click="onLogout()">
                <i class="iconfont icon-tuichu"></i>
                <span>退出登录</span>
                <p class="xian"></p>
            </li>
        </div>
        <!-- <div class="enter">登录</div> -->

        <!-- <el-menu :default-active="activeIndex2" class="el-menu-demo  right exit" mode="horizontal" @select="handleSelect" text-color="#fff" active-text-color="#fff">
		<el-menu-item index="1"><i class="iconfont icon-tuichu1"></i> admin</el-menu-item>
		<el-menu-item index="3"><i class="iconfont icon-tuichu1"></i>个人中心</el-menu-item>
		<el-menu-item index="4"><a href="https://www.ele.me" target="_blank"><i class="iconfont icon-tuichu1"></i>退出</a></el-menu-item>
	</el-menu> -->
        <WebSocket ref="WebSocket"></WebSocket>
        <WeChats ref="WeChats"></WeChats>
    </div>

</template>

<script>
import WebSocket from "../webSocket";
import WeChats from "./WeChat.vue";
export default {
    data () {
        return {
            selected: this.$route.meta.selected,
            topExioList: [
                // {
                //     path: "/",
                //     text: "首页",
                //     icon: "icon-index",
                // },
                // {
                //     path: "/ErLangCha/LubanShop",
                //     text: "二郎查",
                //     icon: "icon-qiye",
                // },
                // {
                //     path: "/flock",
                //     text: "二郎查工具",
                //     icon: "icon-56",
                // },
                // {
                //     path: "/userlist",
                //     text: "用户管理",
                //     icon: "icon-yonghuguanli-gongnengfenpei",
                // },
                // {
                //     path: "/message",
                //     text: "系统管理",
                //     icon: "icon-xitong",
                // },
            ],
            activeIndex: "1",
            activeIndex2: "1",
            account: "",
            wechat_data: JSON.parse(localStorage.getItem('TZ-DATA'))
        };
    },
    components: {
        WebSocket,
        WeChats
    },
    watch: {
        //监听路由变化修改senecten
        $route (to, from) {
            this.selected = to.meta.selected;
            this.ongetMenu()
        },
    },
    mounted () {
        this.account = localStorage.getItem("TZ-account");
        // console.log(this.account);
        this.ongetMenu()
    },

    methods: {
        //绑定微信返回值
        onWechat (data) {
            localStorage.setItem("TZ-DATA", JSON.stringify(data)); //账户所有
            this.wechat_data = JSON.parse(localStorage.getItem('TZ-DATA'));
        },
        //绑定微信
        onWx () {
            this.$refs.WeChats.oncreativity()
        },
        //获取头部菜单栏
        ongetMenu () {
            this.$service.get(this.$api.getMenu, {}, (res) => {
                if (res.code == '200') {
                    // res.data.forEach((v,k) => {

                    // });
                    this.topExioList = res.data
                }
            })
        },
        //跳转页面
        towebdownload (path) {
            this.$router.push(path);
        },
        handleSelect (key, keyPath) {
            // console.log(key, keyPath);
        },
        // 一级栏目跳转
        onToPath (item) {
            this.$parent.onLeftpath(item.text);
            this.$router.push(item.path);
        },
        //退出登录
        onLogout () {
            localStorage.removeItem("TZ-USER");
            this.$router.go(0);
        },
        // 收起侧边栏
        onBarPackup () {
            this.$parent.onBarPackup();
        },
    },

    //   methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){
    // 		}
    // 	})
    // },
    //   },
};
</script>

<style lang="scss" scoped>
.roof {
    display: flex;
    text-align: left;
    position: fixed;
    width: 100%;
    height: 56px;
    background: #001a37;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 9;
    img {
        width: 100px;
        height: 24px;
        margin-left: 40px;
        margin-top: 16px;
        margin-right: 60px;
    }
    .iconfont {
        color: #fff;
        margin-top: 24px;
    }
    .logo {
        display: flex;
        .shrinkage {
            width: 56px;
            height: 56px;
            text-align: center;
            line-height: 60px;
            cursor: pointer;
            background: #113e6f;
            &:hover {
                border-bottom: 2px solid #fff;
            }
        }
    }
    .right {
        margin-left: auto;
        margin-right: 25px;
    }
    .topExio {
        display: flex;
        height: 56px;
        font-weight: 450;
        li {
            padding: 0 20px;
            height: 56px;
            line-height: 60px;
            cursor: pointer;
            color: #fff;
            position: relative;
            i {
                margin-right: 10px;
            }
            .xian {
                position: absolute;
                bottom: 0;
                left: 20px;
                width: calc(100% - 40px);
                height: 2px;
                background: #fff;
                opacity: 0;
                transition: 0.3s;
            }
            &:hover {
                .xian {
                    transition: 0.3s;
                    opacity: 1;
                }
            }
            &.active {
                .xian {
                    opacity: 1;
                }
            }
        }
    }
    .websocket {
        position: fixed;
        width: 300px;
        padding: 10px;
        top: 60px;
        right: 20px;
        background: #fff;
        border-radius: 10px;
        z-index: 100;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        transition: opacity 0.3s, transform 0.3s, left 0.3s, right 0.3s,
            top 0.4s, bottom 0.3s;
        animation: myfirst 2s;
        @-webkit-keyframes myfirst {
            0% {
                right: -400px;
            }
        }
        border-left: 4px solid #0075f7;
        .title {
            font-size: 15px;
            font-weight: 500;
            .icon {
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 22px;
            }
        }
        .myaudio {
            display: none;
        }
        .texthtml {
            .massagehtml {
                position: relative;
                padding-bottom: 50px;
                .el-button {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }
        .paging {
            display: flex;
            position: absolute;
            bottom: 20px;
            .button {
                cursor: pointer;
                padding: 0 5px;
            }
        }
    }
}
</style>
<style lang="scss">
.roof {
    .el-submenu {
        background-color: #001a37 !important;
        // color: #fff !important;
    }
    .el-menu--horizontal > .el-submenu .el-submenu__title {
        color: #fff !important;
    }
    .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
        color: #fff !important;
    }
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
        background-color: #001a37 !important;
        color: #fff !important;
    }
    .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
        color: #fff !important;
    }
}
</style>

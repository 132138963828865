<template>
    <div class="leftside">
        <el-menu :default-active="$route.path" router class="el-menu-vertical-demo" :collapse="isCollapse" unique-opened>
            <li v-for="(item,key) in list" :key="key">
                <el-submenu :key="key" :label="item.text" :index="item.text" v-if="item.children">
                    <template slot="title">
                        <i class="iconfont" :class="item.icon"></i>
                        <span>{{ item.text }}</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item :class="arrp.path == $route.path ? 'is-active' : ''" @click="got(arrp.path)" v-for="arrp in item.children" :key="arrp.value" :value="arrp.value" :label="arrp.value" :index="arrp.path">{{ arrp.text }}</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-menu-item class="erzhuanyi" @click="got(item.path)" :index="item.path" v-if="!item.children">
                    <i class="iconfont" :class="item.icon"></i>
                    <span slot="title">{{item.text}}</span>
                </el-menu-item>
            </li>
        </el-menu>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 侧边栏收起展开
            isCollapse: false,
            DanList: [],
            list: [],
            MenuList: '',//菜单栏
        };
    },
    watch: {
        $route() {
            this.ongetMenu()
            // this.onLeftpath(this.$route.meta.selected);
        },
    },
    mounted() {
        this.ongetMenu()
    },

    methods: {
        //获取头部菜单栏
        ongetMenu() {
            this.$service.get(this.$api.getMenu, {}, (res) => {
                if (res.code == '200') {
                    this.MenuList = res.data
                    this.onLeftpath(this.$route.meta.selected);
                }
            })
        },
        onLeftpath(menu, webopen) {
            if (menu == '首页') {
                if (!this.list.length) {
                    this.onListLeft('二郎查工具')
                }
                return
            }
            this.onListLeft(menu)

            // if (webopen == "二郎查工具") {
            //     this.onListLeft(menu);
            // }else{
            // webopen == "刷新加载" ? webopen
            // this.onListLeft(menu)
            // }
            // if (menu == "二郎查工具") {
            //     this.onPathTollList();
            // }
            // if (menu == "用户管理") {
            //     this.onPathUserlList();
            // }
            // if (menu == "系统管理") {
            //     this.onsystem();
            // }
            // if (menu == "二郎查") {
            //     this.onErLangCha();
            // }
        },
        got(index) {
            if (this.$route.path == index) {
                this.$router.go(0);
            } else {
                this.$router.push(index);
            }
        },
        //收起展开侧边栏
        onBarPackup() {
            this.isCollapse = !this.isCollapse;
        },
        onListLeft(name) {
            // console.log(this.MenuList)
            this.MenuList.forEach((v, k) => {
                if (v.name == name) {
                    this.list = v.children
                }
            });
        },
        //二郎查工具数据
        onPathTollList() {
            this.list = [
                {
                    text: "充值工具",
                    icon: "icon-chongzhi_active",
                    childs: [
                        {
                            index: "/flock",
                            option: "企业列表",
                        },
                        {
                            index: "/operation",
                            option: "运营官列表",
                        },
                        {
                            index: "/grouplist",
                            option: "群列表",
                        },
                        {
                            index: "/AccountList",
                            option: "账户列表",
                        },
                    ],
                },
                {
                    index: "/orderFirm",
                    icon: "icon-dingdan",
                    option: "订单列表",
                },
                {
                    index: "/prot",
                    icon: "icon-duankou",
                    option: "端口管理",
                },
                {
                    text: "财务管理",
                    icon: "icon-caiwujichushezhi",
                    childs: [
                        {
                            index: "/financial/orderList",
                            option: "订单统计",
                        },
                        {
                            index: "/financial/portStatistics",
                            option: "端口统计",
                        },
                    ],
                },
                {
                    index: "/ticketCreate",
                    icon: "icon-chengbenjilu",
                    option: "掉量超成本",
                },
                {
                    text: "退款管理",
                    icon: "icon-duankou1",
                    childs: [
                        {
                            index: "/crossPortList",
                            option: "跨端口退款",
                        },
                        {
                            index: "/topuprefund",
                            option: "充值退款",
                        }, {
                            index: "/yourkind",
                            option: "充值备款",
                        },
                    ],
                },
                {
                    text: "文字识别",
                    icon: "icon-saomiaoshibie380",
                    childs: [
                        {
                            index: "/discern",
                            option: "媒体文件转文字",
                        },
                    ],
                },
                {
                    index: "/lobarreview",
                    icon: "icon-Home_icon_rengongbul-copy",
                    option: "人工工单复审",
                },
                {
                    index: "/consumption",
                    icon: "icon-shangpintongjitongji",
                    option: "消耗统计",
                },

                // {
                //     index: "/crossPortList",
                //     icon: "icon-duankou1",
                //     option: "跨端口列表",
                // },
                // {
                //     index: "/a",
                //     icon: "icon-duankouguanli",
                //     option: "跨端口管理",
                // },
            ];
        },
        //用户管理
        onPathUserlList() {
            this.list = [
                {
                    text: "用户列表",
                    icon: "icon-qunzu",
                    childs: [
                        {
                            index: "/userlist",
                            option: "用户列表",
                        },
                        // {
                        //     index: "/user2",
                        //     option: "用户列表2",
                        // },
                    ],
                },
            ];
        },
        //系统管理
        onsystem() {
            this.list = [
                {
                    text: "消息管理",
                    icon: "icon-xiaoxi",
                    childs: [
                        {
                            index: "/message",
                            option: "消息模板",
                        },
                        {
                            index: "/inform",
                            option: "通知列表",
                        },
                    ],
                },
                {
                    text: "访问日志",
                    icon: "icon-duankou1",
                    childs: [
                        {
                            index: "/accesslog",
                            option: "访问统计",
                        },
                        {
                            index: "/BatchPlan",
                            option: "批量计划访问统计",
                        },
                        {
                            index: "/source",
                            option: "访问来源统计",
                        },
                        {
                            index: "/BatchPlan",
                            option: "批量计划访问统计",
                        },
                    ],
                },
                {
                    text: "权限管理",
                    icon: "icon-duankou1",
                    childs: [
                        {
                            index: "/AdminUser",
                            option: "用户权限",
                        },
                        {
                            index: "/AdminList",
                            option: "权限列表",
                        },
                        {
                            index: "/menuIndex",
                            option: "菜单栏",
                        },
                    ],
                },
            ];
        },
        //二郎查
        onErLangCha() {
            this.list = [
                {
                    text: "数据管理",
                    icon: "icon-xiaoxi",
                    childs: [
                        {
                            index: "/ErLangCha/LubanShop",
                            option: "鲁班商品",
                        },
                        {
                            index: "/ErLangCha/LubanStore",
                            option: "鲁班店铺",
                        },
                    ],
                },
                {
                    text: "素材配音",
                    icon: "icon-peiyin3",
                    childs: [
                        {
                            index: "/ErLangCha/teacher",
                            option: "配音师列表",
                        },
                        {
                            index: "/ErLangCha/dubOrder",
                            option: "配音订单列表",
                        },
                        {
                            index: "/ErLangCha/VideoOrder",
                            option: "视频订单列表",
                        },
                        {
                            index: "/ErLangCha/integral",
                            option: "积分下单设置",
                        },
                    ],
                },
                {
                    index: '/ErLangCha/contrast',
                    option: "对比店铺",
                    icon: "icon-jiancedianduibifenxi",
                },
                {
                    index: '/ErLangCha/urlLink',
                    option: "友情链接",
                    icon: "icon-youqinglianjie",
                }, {
                    text: "文章管理",
                    icon: "icon-peiyin3",
                    childs: [
                        {
                            index: "/ErLangCha/article",
                            option: "作者列表",
                        },
                        {
                            index: "/ErLangCha/author",
                            option: "文章列表",
                        },
                    ],
                },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
.leftside {
    // width: 200px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 56px;
    background: #fff;
    box-shadow: 5px 0px 6px 0px rgba(0, 0, 0, 0.13);
}
</style>
<style lang="scss">
.leftside {
    .el-menu {
        // transition:3s;
        .iconfont {
            vertical-align: middle;
            margin-right: 12px;
            width: 24px;
            text-align: center;
            font-size: 16px;
        }
        border: none;
        text-align: left;
        width: 200px;
        &.v-leave-active {
            // width: 64px !important;
        }
        .el-submenu__title {
            // margin-left: -10px;
            font-size: 15px;
        }
        .el-menu-item-group__title {
            display: none;
        }
        // .horizontal-collapse-transition {
        //     transition: 5s width ease-in-out,5s padding-left ease-in-out,5s padding-right ease-in-out;
        // }
        .el-menu-item {
            color: #666;
            font-size: 14px;
            height: 45px;
            line-height: 45px;
            padding-left: 50px !important;
            &.is-active {
                color: #0075f7;
                background: #ecf5ff;
            }
            &.erzhuanyi {
                height: 56px;
                line-height: 56px;
                padding-left: 20px !important;
                color: #333;
                &.is-active {
                    color: #0075f7;
                    background: #ecf5ff;
                }
                span {
                    font-size: 15px;
                }
            }
        }
        &.v-enter-to {
            width: 200px;
            transition: width 0.5s;
        }
        &.v-leave-to {
            width: 64px;
            transition: width 0.5s;
        }
        &.el-menu--collapse {
            width: 64px;
            .el-submenu__title {
                margin-left: 0;
                font-size: 15px;
                span,
                .el-icon-arrow-right {
                    display: none;
                }
            }
        }
    }
    .horizon {
        transition: 5s;
    }
}
.el-menu--vertical {
    .el-menu-item-group__title {
        display: none;
    }
}
</style>

import {
    userInfo
} from "os";
var HOST = process.env.VUE_APP_URL;
var DUB = process.env.VUE_APP_DUB;
var USER = process.env.VUE_APP_URL_USER;
// var HOST = "/api";
// var HOST = "";
export default {
    HOST,
    /**
     * @method GET
     * @params banner
     * @return {"code":200, "msg":"success"}
     */
    getTopCorporateAccountList: HOST + '/api/ocea/getTopCorporateAccountList', //获取企业与搜索企业
    getTopCorporateAccountConfig: HOST + '/api/ocea/getTopCorporateAccountConfig', //获取编辑企业
    getTopOperatingList: HOST + '/api/ocea/getTopOperatingList', //获取运营官
    createTopCorporateAccountConfig: HOST + '/api/ocea/createTopCorporateAccountConfig', //配置企业   编辑企业
    createTopOperating: HOST + '/api/ocea/createTopOperating', //编辑运营
    getTopOperatingRes: HOST + '/api/ocea/getTopOperatingRes', //编辑运营官-查询运营官信息

    getTopOrderList: HOST + '/api/ocea/getTopOrderList', //获取订单列表
    updateOrderNote: HOST + '/api/ocea/updateOrderNote', //修改订单备注
    getTopOrderDetail: HOST + '/api/ocea/getTopOrderDetail', //获取订单详情
    updateOrderStatus: HOST + '/api/ocea/updateOrderStatus', //审核订单
    getTopBankCardList: HOST + '/api/ocea/getTopBankCardList', //获取银行卡信息 
    getAdvertiserList: HOST + '/api/ocea/getAdvertiserList', //获取端口信息
    signIn: HOST + '/api/manage/signIn', //获取端口信息
    getTopCompanyList: HOST + '/api/ocea/getTopCompanyList', //选择群
    getTopAgentAccountList: HOST + "/api/ocea/getTopAgentAccountList", //获取账户列表
    //  upload:HOST+"/api/file/upload",                        //上传图片
    getImageOcr: "/user/top/getImageOcr", //获取金额
    upload: "/user/file/upload", //上传图片
    createTopOrder: HOST + "/api/ocea/createTopOrder", //充值账户
    getServeUserList: HOST + "/api/server/getServeUserList", //  获取用户列表
    getUserByLastAction: HOST + "/api/server/getUserByLastAction", //  获取用户列表
    createTopAgentAccount: HOST + "/api/ocea/createTopAgentAccount", //  保存账户
    getTopAgentAccountRes: HOST + "/api/ocea/getTopAgentAccountRes", //  编辑账户
    createAdvertiser: HOST + "/api/ocea/createAdvertiser", //  保存端口
    getAdvertiserRes: HOST + "/api/ocea/getAdvertiserRes", //  编辑端口
    getTopOrderDetailJoinOrderExport: HOST + "/api/ocea/getTopOrderDetailJoinOrderExport", //导出
    // getServeUserList:HOST+"/api/server/getServeUserList",//获取用户列表
    distributionTopCorporateAccountUserConfig: HOST + "/api/ocea/distributionTopCorporateAccountUserConfig", //企业分配用户、运营官、群
    createTopCompany: HOST + "/api/ocea/createTopCompany", //新增群
    getSysMessageTemp: HOST + "/api/manage/getSysMessageTempList", //消息模板
    createSysMessageTemp: HOST + "/api/manage/createSysMessageTemp", //编辑添加模板
    getSysMessageUserList: HOST + "/api/manage/getSysMessageUserList", //通知列表
    delSysMessageUser: HOST + "/api/manage/delSysMessageUser", //移除消息通知者
    createSysMessageUserList: HOST + "/api/manage/createSysMessageUserList", //添加消息接收者
    distributionTopCompanyAgentConfig: HOST + "/api/ocea/distributionTopCompanyAgentConfig", //配置群
    getTopFinancialList: HOST + "/api/ocea/getTopFinancialList", //财务结算列表
    updateTopFinancialForTheAmount: HOST + "/api/ocea/updateTopFinancialForTheAmount", //修改备款金额
    getTopOrderDetailJoinOrder: HOST + "/api/ocea/getTopOrderDetailJoinOrder", //财务管理-订单统计
    getTopFinancialLogList: HOST + "/api/ocea/getTopFinancialLogList", //财务管理-获取端口统计详情
    show: HOST + "/api/manage/show", //首页信息
    adminLoginLog: HOST + "/api/manage/adminLoginLog", //登录日志
    getPortTransferList: HOST + "/api/ocea/getPortTransferList", //跨端口列表
    uploadTcaAttachment: HOST + "/api/ocea/uploadTcaAttachment", //企业附件添加
    getTcaAttachment: HOST + "/api/ocea/getTcaAttachment", //获取企业附件列表
    getWeblogList: HOST + "/api/server/getWeblogList", //获取访问日志
    getWeblogDetail: HOST + "/api/server/getWeblogDetail", //获取访问日志详情
    getShopAggregate: HOST + "/api/server/getShopAggregate", //获取鲁班商品访问日志
    getCategoryItems: HOST + "/api/manage/getCategoryItems", //品类排名获取列表
    getWebLogParamsList: HOST + "/api/server/getWebLogParamsList", //获取来源
    getWebLogParamsInfo: HOST + "/api/server/getWebLogParamsInfo", //获取来源详情

    bindWechat: HOST + '/api/rbac/bindWechat', // 绑定微信
    getBindWechatResult: HOST + '/api/rbac/getBindWechatResult', // 绑定微信回调
    setWebsiteArticleSort: HOST + '/api/erlangcha/setWebsiteArticleSort', //文章排序
    getHolidayList: HOST + '/api/dub/getHolidayList', //节假日
    setHolidayField: HOST + '/api/dub/setHolidayField', //节假日编辑
    add_task: HOST + '/api/ocea/verification/add_task', //添加任务
    getTask: HOST + '/api/ocea/verification/get', //获取任务列表
    download_result: HOST + '/api/ocea/verification/download_result', //下载计算结果


    get_netflow_orders: HOST + "/api/recheck/get_netflow_orders", //掉量提审订单
    get_netflow_order_detail: HOST + "/api/recheck/get_netflow_order_detail", //掉量提审订单详情
    getTopOrderRefundList: HOST + "/api/ocea/getTopOrderRefundList", //获取退款列表
    updateOrderRefundNote: HOST + "/api/ocea/updateOrderRefundNote", //编辑备注
    delOrderRefundNote: HOST + "/api/ocea/delOrderRefundNote", //删除退款订单
    createTopOrderRefund: HOST + "/api/ocea/createTopOrderRefund", //编辑退款
    customRepairOrderList: HOST + "/api/ocea/customRepairOrderList", //获取需要人工工单复审的数据
    customRepairOrderRes: HOST + "/api/ocea/customRepairOrderRes", //获取需要人工工单复审获取详情
    createCustomRepairOrder: HOST + "/api/ocea/createCustomRepairOrder", //获取需要人工工单复审获取编辑
    getTopOrderRefundCopyList: HOST + "/api/ocea/getTopOrderRefundCopyList", //备款列表
    updateOrderRefundNoteCopy: HOST + "/api/ocea/updateOrderRefundNoteCopy", //备款编辑
    createTopOrderCopyRefund: HOST + "/api/ocea/createTopOrderCopyRefund", //备款备注
    getCompanyJoinList: HOST + "/api/ocea/getCompanyJoinList", //企业总览

    //二郎查
    getErShopHistoryList: HOST + "/api/erlangcha/getErShopHistoryList", //历史商品去年100
    getErStoreList: HOST + "/api/erlangcha/getErStoreList", //获取店铺列表
    getErShopList: HOST + "/api/erlangcha/getErShopList", //获取二郎查商品
    getErShopListExport: HOST + "/api/erlangcha/getErShopListExport", //二郎查商品导出
    getErShopExport: HOST + "/api/erlangcha/getErShopExport", //轮询获取鲁班商品导出excel
    getExportJobList: HOST + "/api/manage/getExportJobList", //获取导出列表
    whileGetExportJob: HOST + "/api/manage/whileGetExportJob", //轮询获取鲁班商品导出excel
    getMixerList: DUB + "/api/dub/getMixerList", //获取配音师列表
    setMixerLevel: DUB + "/api/dub/setMixerLevel", //设置配音师星级
    delMixer: DUB + "/api/dub/delMixer", //删除配音师
    setMixerStatus: DUB + "/api/dub/setMixerStatus", //配音师状态
    setMixerSource: DUB + "/api/dub/setMixerSource", //设置配音师来源
    setMixerOnlineTime: DUB + "/api/dub/setMixerOnlineTime", //配音师在线时间
    getMixerLabelList: DUB + "/api/dub/getMixerLabelList", //标签列表
    getMixerAccentList: DUB + "/api/dub/getMixerAccentList", //配音师方言
    uploadFile: DUB + "/api/dub/uploadFile", //上传文件
    videoToImg: DUB + "/api/dub/videoToImg", //上传文件切帧
    addMixer: DUB + "/api/dub/addMixer", //新增配音师
    editMixer: DUB + "/api/dub/editMixer", //编辑配音师上传
    getMixerInfo: DUB + "/api/dub/getMixerInfo", //编辑配音师获取详情
    setMixerSorf: DUB + "/api/dub/setMixerSorf", //配音师提交
    getDubOrderList: DUB + "/api/dub/getDubOrderList", //配音师订单列表
    getDubOrderMixerByLevel: DUB + "/api/dub/getDubOrderMixerByLevel", //获取修改配音师列表
    alterDubOrderMixer: DUB + "/api/dub/alterDubOrderMixer", //修改配音师 
    setMixerOld: DUB + "/api/dub/setMixerOld", //设置配音师资历 
    getDubOrderPayInfo: DUB + "/api/dub/getDubOrderPayInfo", //获取支付详情
    getDubRecordsList: DUB + "/api/dub/getDubRecordsList", //获取配音反馈
    dubRefund: DUB + "/api/dub/dubRefund", //退款
    dubRecordUploadFile: DUB + "/api/dub/dubRecordUploadFile", //上传配音
    dubOrderBatchExport: DUB + "/api/dub/dubOrderBatchExport", //导出
    getAuthorsList: DUB + "/api/erlangcha/getAuthorsList", //作者列表
    createAuthors: DUB + "/api/erlangcha/createAuthors", //编辑作者
    setAuthorsStatus: DUB + "/api/erlangcha/setAuthorsStatus", //编辑作者
    setWebsiteArticleTitleType: DUB + "/api/erlangcha/setWebsiteArticleTitleType", //文章编辑
    setWebsiteArticleType: DUB + "/api/erlangcha/setWebsiteArticleType", //文章类型
    getAdUserList: DUB + "/api/server/getAdUserList", //批量计划用户列表
    createAdUser: DUB + "/api/server/createAdUser", //添加编辑用户
    getAllAdUserLogList: DUB + "/api/server/getAllAdUserLogList", //批量计划列表
    getAllAdUserTotal: DUB + "/api/server/getAllAdUserTotal", //批量计划


    getWebsitePostList: DUB + "/api/erlangcha/getWebsitePostList", //有问必答列表
    setPostClass: DUB + "/api/erlangcha/setPostClass", //设置问答类型
    setPostStatus: DUB + "/api/erlangcha/setPostStatus", //设置问答状态
    getWebsitePostMessageList: DUB + "/api/erlangcha/getWebsitePostMessageList", //获取评论列表
    setPostMessageStatus: DUB + "/api/erlangcha/setPostMessageStatus", //设置回答评论状态
    batchRemove: DUB + "/api/erlangcha/batchRemove", //批量删除


    getWebsiteArticleList: DUB + "/api/erlangcha/getWebsiteArticleList", //文章列表
    setWebsiteArticleIsShow: DUB + "/api/erlangcha/setWebsiteArticleIsShow", //文章显示
    // setWebsiteArticleSort: DUB + "/api/erlangcha/setWebsiteArticleSort", //文章排序
    getWebsiteTypeList: DUB + "/api/erlangcha/getWebsiteTypeList", //文章分类
    articleDetailEdit: DUB + "/api/erlangcha/articleDetailEdit", //新增文章
    articleDel: DUB + "/api/erlangcha/articleDel", //删除文章

    getVideoOrderList: DUB + "/api/dub/getVideoOrderList", //获取视频列表
    videoOrderBatchExport: DUB + "/api/dub/videoOrderBatchExport", //视频导出
    getVideoClassifyTree: DUB + "/api/dub/getVideoClassifyTree", //视频分类树
    getMixerListDropDownBox: DUB + "/api/dub/getMixerListDropDownBox", //获取配音师列表
    getVideoOrderPayInfo: DUB + "/api/dub/getVideoOrderPayInfo", //获取支付详情
    setOrderStatus: DUB + "/api/dub/setOrderStatus", //获取支付详情
    batchOperateOrderStatus: DUB + "/api/dub/batchOperateOrderStatus", //批量操作修改状态

    dubRecordUploadFileAgain: DUB + "/api/dub/dubRecordUploadFileAgain", //重新上传
    getAdUserLogList: DUB + "/api/server/getAdUserLogList", //批量计划访问列表
    getAdUserTotal: DUB + "/api/server/getAdUserTotal", //批量计划访问统计



    getMixersampleVideoList: DUB + "/api/dub/getMixersampleVideoList", //查看案例
    delMixersampleVideo: DUB + "/api/dub/delMixersampleVideo", //删除视频    
    addMixersampleVideo: DUB + "/api/dub/addMixersampleVideo", //添加视频 
    editMixersampleVideo: DUB + "/api/dub/editMixersampleVideo", //编辑视频 


    getInterOrderSampleList: DUB + "/api/dub/getInterOrderSampleList", //获取样品
    editShopClassName: DUB + "/api/erlangcha/editShopClassName", //给商品起别名


    //积分设置
    getIntegralUserList: DUB + "/api/dub/getIntegralUserList", //积分设置列表
    editIntegralUser: DUB + "/api/dub/editIntegralUser", //新增与编辑
    delIntegralUser: DUB + "/api/dub/delIntegralUser", //删除
    setIntegralUserStatus: DUB + "/api/dub/setIntegralUserStatus", //设置状态
    // 文字识别
    getMediaToTextList: HOST + "/api/ocea/media/getMediaToTextList", //媒体文件转文字
    postExcelCost: HOST + "/api/manage/cost/postExcelCost", //excel消耗统计
    ExcegetList: HOST + "/api/manage/cost/getList", //excel数据转换消获取列表
    searchById: HOST + "/api/manage/cost/searchById", //excel数据转换查询状态
    setUserRemark: DUB + "/api/dub/setUserRemark", //配音订单提交备注



    // 自运营对比店铺
    getCompanyList: HOST + "/api/ocea/getCompanyList", //获取企业列表
    getTopCorporateAccountStoriesList: HOST + "/api/ocea/getTopCorporateAccountStoriesList", //获取自运营对比店铺的列表
    getStoriesShopSalesVolumeTopTen: HOST + "/api/ocea/getStoriesShopSalesVolumeTopTen", //获取店铺商品销量TOP10
    weblogSearch: HOST + "/api/server/weblogSearch", //搜索词

    // 友情链接
    getBlogrollList: HOST + "/api/erlangcha/getBlogrollList", //获取列表
    createBlogroll: HOST + "/api/erlangcha/createBlogroll", //添加友情链接
    setBlogrollStatus: HOST + "/api/erlangcha/setBlogrollStatus", //设置状态
    delBlogroll: HOST + "/api/erlangcha/delBlogroll", //删除





    //权限列表
    getAdminList: HOST + "/api/rbac/getAdminList", //后台管理员用户列表
    createAdmin: HOST + "/api/rbac/createAdmin", //添加编辑管理员
    delAdmin: HOST + "/api/rbac/delAdmin", //删除管理员
    allocationPrivilege: HOST + "/api/rbac/allocationPrivilege", //分配管理员权限
    getAdminPrivilegeList: HOST + "/api/rbac/getAdminPrivilegeList", //获取用户权限
    getPrivilegeList: HOST + "/api/rbac/getPrivilegeList", //获取权限列表
    createPrivilege: HOST + "/api/rbac/createPrivilege", //添加权限
    getMenu: HOST + "/api/rbac/getMenu", //菜单栏

    // 对账列表
    getAccount: HOST + "/api/ocea/verification/adv/get", //筛选账户查询
    deleteAccount: HOST + "/api/ocea/verification/adv/delete", //筛选账户删除
    updateAccount: HOST + "/api/ocea/verification/adv/update", //筛选账户修改
    add_manyAccount: HOST + "/api/ocea/verification/adv/add_many", //筛选账户添加
    add_manyProduct: HOST + "/api/ocea/verification/product/add_many", //筛选产品添加
    updateProduct: HOST + "/api/ocea/verification/product/update", //筛选产品修改
    getProduct: HOST + "/api/ocea/verification/product/get", //筛选产品查询
    deleteProduct: HOST + "/api/ocea/verification/product/delete", //筛选产品删除
    addpersonnel: HOST + "/api/ocea/verification/personnel/add", //筛选人员添加
    updatepersonnel: HOST + "/api/ocea/verification/personnel/update", //筛选人员修改
    getpersonnel: HOST + "/api/ocea/verification/personnel/get", //筛选人员查询
    deletepersonnel: HOST + "/api/ocea/verification/personnel/delete", //筛选人员删除



    //后台首页数据
    getNotLoginUserIpCount: HOST + "/api/manage/getNotLoginUserIpCount", //每天未登录用户ip数列表
    getAllIpCount: HOST + "/api/manage/getAllIpCount", //IP访问数列表
    setServerUserStatus: HOST + "/api/server/setServerUserStatus", //设置用户状态
    saveIndustryStatus: HOST + "/api/server/saveIndustryStatus", //设置素材标签权限
    setServerUserAuthStatus: HOST + "/api/server/setServerUserAuthStatus", //访问权限
    // 插件激活码
    getRegistrationCodeList: HOST + "/api/ocea/getRegistrationCodeList", //列表
    createRegistrationCode: HOST + "/api/ocea/createRegistrationCode", //生成注册码
    delRegistrationCode: HOST + "/api/ocea/delRegistrationCode", //删除注册码



    extendgetList: HOST + "/api/erlangcha/extend/get_list", //下单插件查询店铺列表
    extenddelete: HOST + "/api/erlangcha/extend/delete", //下单插件删除店铺
    extendsave: HOST + "/api/erlangcha/extend/save", //下单插件添加修改



























}
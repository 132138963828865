
import axios from 'axios';
import { MessageBox, Message } from "element-ui";
import { Loading } from 'element-ui';
var loading;
// Loading.service(options);
axios.defaults.withCredentials = true
var serivce = {
    get: function (url, params, callback) {
        if(localStorage.getItem('TZ-USER')){
           var token = JSON.parse(localStorage.getItem('TZ-USER')).admin_token 
        }
        if(!params.deleteJiaZai){
            loading = Loading.service({
                lock:true,    //是否锁定
                text:"拼命加载中...",   //显示在加载图标下方的加载文案
                background:'rgba(0,0,0,.7)',   //遮罩背景色
            });
        }
        else{
            delete params.deleteJiaZai
        }
        
        var req = {
            url: url,
            method: "get",
            params: params,
            headers:{
                token:token ? token : ''
            }
        }
        axios.request(req).then(res => {
            setTimeout(() => {
                loading.close();
            }, 100);
           callback(res.data);
            if(res.data.code == '400'){
                Message({
                    message: 'error',
                    type: "error",
                });
            }if(res.data.code != 200){
                Message({
                    message: res.data.message ? res.data.message : res.data.msg ? res.data.msg : 'error',
                    type: "error",
                });
            }
            if(res.data.code == '5001'){
                localStorage.removeItem("TZ-USER")
                window.location.href = '/login'
            }
            else {
                // return callback(res.data);
            }
            
        }).catch((err)=>{
            loading.close();
            Message({
                message: '网络繁忙，请稍后再试',
                type: "error",
                duration: 5 * 1000
            });
            console.log(err + url + '请检查网络');
        });
    }

    , post: function (url, params, callback,params_header={}) {
        if(localStorage.getItem('TZ-USER')){
        //    var token = process.env.NODE_ENV == 'development' ? 'b09b8c771f0a359d59066f034dce05f0' : JSON.parse(localStorage.getItem('TZ-USER')).token 
           var token = JSON.parse(localStorage.getItem('TZ-USER')).admin_token
        }
        if(!params_header.token){
            var token = token ? token : ''
            params_header.token = token;
        }
        if(!params.deleteJiaZai){
            loading = Loading.service({
                lock:true,    //是否锁定
                text:"拼命加载中...",   //显示在加载图标下方的加载文案
                background:'rgba(0,0,0,.7)',   //遮罩背景色
            });
        }else{
            delete params.deleteJiaZai
        }
        axios.request({
            url: url,
            method: "post",
            dataType: 'json',
            headers:params_header,
            data: params,
        }).then(res => {
            setTimeout(() => {
                loading.close();
            }, 100);
           callback(res.data);
            if(res.data.code != 200){
                Message({
                    message: res.data.message ? res.data.message : res.data.msg ? res.data.msg : 'error',
                    type: "error",
                });
            }
            // if(res.data.code == '5001'){
            //     localStorage.removeItem("TZ-USER")
            //     window.location.href = '/login'
            // }else {
            //     // return callback(res.data);
            // }
        }).catch(err=>{
            loading.close();
            Message({
                message: '网络繁忙，请稍后再试',
                type: "error",
                duration: 5 * 1000
            });
        });
    }
}

export default serivce;



<template>
    <div class="Wechat">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="绑定微信" :visible.sync="ticklingDialog" :closeOnClickModal='false' width="700px" @close="closeDialog()" append-to-body>
                <el-form style="margin-top:25px">
                    <div style=" width: 200px; height: 220px; margin: 0 auto; margin-bottom:20px">
                        <img :src="imgurl" alt="" srcset="" class="img" style=" width: 200px;height: 200px;">
                        <p style="text-align: center;">请扫码登录</p>
                    </div>

                </el-form>
                <div slot="footer" class="dialog-footer">
                    <!-- <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button> -->
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ticklingDialog: false, //操控弹窗展示
            imgurl: "",
            state: "",
            Result: '',
            timer: null
        };
    },
    mounted() {
        if (this.state != '') {
            setTimeout(() => {
                this.bindWechat()
            }, 30000);
        }
    },
    methods: {
        oncreativity() {
            this.ticklingDialog = true;
            this.bindWechat()
        },
        bindWechat() {
            this.$service.get(this.$api.bindWechat, "", (res) => {
                if (res.code == '200') {
                    this.state = res.data.state;
                    this.imgurl = "data:image/jpg;base64," + res.data.redirect_code;
                    this.onState()
                }
            })
        },
        onState() {
            var param = {
                state: this.state,
                deleteJiaZai: true
            }
            this.$service.get(this.$api.getBindWechatResult, param, (res) => {
                if (res.code == '200') {
                    this.Result = res.data;
                    if (this.Result == null) {
                        this.timer = setTimeout(() => {
                            this.onState()
                        }, 2000);

                    } else {
                        this.$parent.onWechat(this.Result)
                        clearInterval(this.timer);
                        this.$message({
                            message: "绑定成功!",
                            type: "success",
                        });
                        return;

                    }
                }
            })
        },
        closeDialog() {
            clearInterval(this.timer);
            this.ticklingDialog = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.Wechat {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    //
}
</style>
<style lang="scss">
.upload-demo {
    display: inline-block !important;
}
.el-upload-dragger {
    height: 150px !important;
}
.el-upload__tip {
    width: 250px !important;
}
.el-dialog__header {
    background: #0075f7 !important;
}
.el-dialog__title {
    color: #ffffff !important;
}
.el-form-item__content {
    display: flex !important;
}
.el-radio {
    line-height: 40px !important;
}
</style>